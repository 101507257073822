@import "../../assets/styles/main.module";

.radioBtnContainer {
  margin-top: 15px;
  &.disabled {
    opacity: 0.3;
  }
  &:first-child {
    margin: 0px;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
    .checkArea {
      position: relative;
      width: 20px;
      height: 20px;
      border: 2px solid color(purple);
      border-radius: 50%;
    }
    input[type="radio"] {
      margin: 0;
      width: 0;
      opacity: 0;
      &:checked + .checkArea {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: color(purple);
        }
      }
    }
    p {
      margin: 0px 0px 0px 5px;
      user-select: none;
    }
  }
}
