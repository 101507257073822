@import "../../assets/styles/main.module";
.loaderContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  span {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50%;
    margin: 0px 3px;
    &:nth-child(1) {
      animation: bounce 1s ease-in-out infinite;
    }
    &:nth-child(2) {
      animation: bounce 1s ease-in-out 0.33s infinite;
    }

    &:nth-child(3) {
      animation: bounce 1s ease-in-out 0.66s infinite;
    }
  }
}

@keyframes bounce {
  0%,
  75%,
  100% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(-10px);
  }
}
// @keyframes bounce {
//   0%,
//   100% {
//     transform: translateY(0px);
//   }
//   25% {
//     transform: translateY(-10px);
//   }
//   50% {
//     transform: translateY(10px);
//   }
// }
