@import "../../assets/styles/main.module";

.searchBox{
    width: 100%;
    margin-right: 15px;
}

.searchBox input[type="text"]
{
    width: inherit;
}