@import "../../assets/styles/main.module";

.addNew {
  .error {
    color: color(error);
    font-size: 14px;
    line-height: 16px;
  }
  .submitPlc {
    margin: 50px 0 0;
    border-top: 1px solid #e9eff4;
    display: flex;
    justify-content: center;
    color: darken(#ee2737, 20%);
    &.disabled {
      // opacity: 0.3;

      & > div {
        &:nth-child(2) {
          button {
            opacity: 0.3;
            cursor: not-allowed;
          }
        }
      }
    }
    & > div {
      margin: 30px 0 0 20px !important;
      button {
        min-width: 200px;
      }
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
}
