@import "../../assets/styles/main.module";

.selection {
  position: relative;
  font-size: 14px;
  border-radius: 8px !important;
  padding: 12px 20px !important;
  & > div:nth-child(1) {
    span {
      color: color(blue-grey);
      font-size: 14px;
      line-height: 16px;
    }
  }
}
