@import "../../../assets/styles/main.module";

.container {
  background: color(purple) !important;
}
.modalWithLogo {
  position: relative;
  z-index: 2;
  background: #ffffff;
  width: 80%;
  margin: auto;
  padding: 20px 35px;
  border-radius: 30px;
  box-shadow: 8px 8px 0 0 rgba(255, 255, 255, 0.4);
  display: flex;
  flex-flow: column;
  align-items: center;
  .goTo {
    position: absolute;
    top: 20px;
    display: flex;
    &.left {
      left: 15px;
      a {
        padding-left: 20px;
        img {
          left: 0;
          transform: rotate(90deg);
        }
      }
    }
    &.right {
      right: 15px;
      a {
        padding-right: 20px;
        img {
          right: 0;
          transform: rotate(-90deg);
        }
      }
    }
    a {
      position: relative;
      font-size: 14px;
      line-height: 16px;
      color: color(lightGrey);
      text-decoration: none;
      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0px;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .logoPlc {
    margin: 30px 0 0;
    .imgCont {
      @include imgCont;
      width: 250px;
      height: 83px;
    }
  }
  .content {
    color: color(primary);
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 80%;
    text-align: center;
    margin: 35px 0 0;
    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 24px;
      margin: 0px;
    }
    .subTitle {
      font-size: 14px;
      font-weight: lighter;
      line-height: 16px;
      margin: 5px 0px 0px;
      &.error {
        color: color(error);
      }
    }
  }
}
