@import "../../../assets/styles/main.module";

.modalState {
  position: relative;
  background: #ffffff;
  width: 80%;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  .btnClose {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .imgCont {
    position: relative;
    width: 40%;
    padding-bottom: 40%;
    margin: auto;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content {
    position: relative;
    margin: 20px 0px 0px;
    h4 {
      font-size: 22px;
      line-height: 24px;
      margin: 0px;
      text-align: center;
    }
    p {
      margin: 10px 0px 0px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
}
