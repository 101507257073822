@import "../../assets/styles/main.module";

.btnPlc {
  .buttonApp {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    // font-family: font(demi);
    color: color(white);
    background: color(purple);
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      @include imgCont;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
    &:hover {
      background: lighten(color(purple), 10%);
    }
    &.borderBtn {
      color: color(purple);
      border: 1px solid color(purple);
      background: transparent;
      &:hover {
        color: lighten(color(purple), 10%);
        border: 1px solid lighten(color(purple), 10%);
      }
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
