@import "../../assets/styles/main.module";

.header {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 99;
  background: color(white);
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.23);
  .logo {
    @include imgCont;
    width: 85px;
    height: 42px;
  }
  .headerContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    max-width: 480px;
    margin: auto;
    @include media(miniDesktop) {
      justify-content: space-between;
      max-width: none;
      padding: 0px 25px;
    }
    .breadCrumb {
      p {
        color: color(black);
        font-size: 14px;
        line-height: 16px;
        margin: 0;
      }
    }
    .btnMobile {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto 0;
      background: transparent;
      width: 28px;
      height: 28px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      -webkit-tap-highlight-color: transparent;
      display: none;
      @include media(miniDesktop) {
        display: none;
      }
      &.active {
        span {
          position: absolute;
          transition: 0.5s;
          transform-origin: center;
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            width: 100%;
            transform: rotate(-45deg);
          }
        }
      }

      span {
        content: "";
        display: block;
        height: 2px;
        background: color(red);
        transition: 0.5s;
        transform-origin: center;
        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          width: 60%;
          margin: 6px 0px;
        }
        &:nth-child(3) {
          width: 80%;
        }
      }
    }
    .welcome {
      display: none;
      @include media(miniDesktop) {
        display: flex;
        align-items: center;
      }
      h4 {
        margin: 0;
        color: color(black);
        font-size: 16px;
        line-height: 18px;
      }
      .btnLogout,
      .btnSetting {
        @include imgCont;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin: 0 0 0 10px;
      }
    }
  }
}
