$colors: (
  error: #ff0000,
  red: #ff0000,
  white: #ffffff,
  black: #000000,
  blackApp: #231f20,
  lightBlack: #52575c,
  lightBlack2: #4d4d4d,
  lightGrey: #878787,
  greyPurple: #b9c7d8,
  purple: #3a43ac,
  green: #6dd400,
  yellow: #f7b500,
);

$primary: "Nunito Sans", sans-serif, -apple-system;

@function font($name) {
  @if $name == primary {
    @return $primary;
  }
}

$boxShadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
$boxShadow2: 0 10px 20px 3px rgba(49, 53, 59, 0.2);
$boxShadow2rev: 0 -10px 20px 3px rgba(49, 53, 59, 0.2);

$mediaQueries: (
  miniMobile: 320px,
  mobile: 480px,
  tablet: 768px,
  miniDesktop: 992px,
  desktop: 1200px,
);

@function color($colorName) {
  @return map-get($colors, $colorName);
}

@function mediaFunction($pixel) {
  @return map-get($mediaQueries, $pixel);
}
