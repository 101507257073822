@import "../../assets/styles/main.module";

.headerContent {
  .content {
    display: flex;
    .imgCont {
      cursor: pointer;
      width: 28px;
      height: 28px;
      @include imgCont;
      img {
        // max-width: 10%;
        // max-height: 10%;
      }
    }
    .title {
      margin: 0 0 0 5px;
    }
    h2 {
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      margin: 0px;
      &:only-child {
        line-height: 28px;
      }
    }
    p {
      font-size: 14px;
      line-height: 16px;
      margin: 10px 0px 0px;
      color: color(lightBlack2);
    }
  }
}
