@import "../../assets/styles/main.module";

.dashboard {
  .headerContent {
    @include media(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.notSuper {
      opacity: 1;
      & > div {
        &:nth-child(2) {
          flex: unset;
        }
      }
    }
    & > div {
      &:nth-child(2) {
        margin-left: 30px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          &:last-child {
            justify-self: flex-end;
          }
        }
      }
    }
    .addBtn {
      &.disabled {
        button {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
      button {
        font-size: 14px;
        padding: 12px 50px;
      }
    }
  }
}
