@import "../../assets/styles/main.module";

.formAddNew {
  margin-top: 50px;
  .section {
    display: flex;
    margin: 50px 0 0;
    &:nth-child(1) {
      margin: 0;
    }
    // &.measure {
    //   .inputContainer {
    //     width: 25%;
    //   }
    // }
    & > div {
      margin-left: 50px !important;
      &:nth-child(1) {
        margin-left: 0 !important;
      }
    }
    .inputContainer {
      width: 35%;
    }
    .richText {
      width: calc(70% + 50px);
      &.over {
        & > p > span {
          color: color(error);
        }
      }
    }
  }
}
