@import "../../assets/styles/main.module";

.breadCrumb {
  position: relative;
  flex: 1;
  z-index: 1;
  display: flex;
  margin: 0 0 0 35px;
  .content {
    display: flex;
    flex-flow: row;
    .item {
      display: flex;
      align-items: center;
      margin: 0 0 0 15px;
      &:first-child {
        margin: 0;
      }
      &.active {
        p {
          cursor: default;
          color: #687178;
          font-weight: bold;
        }
      }
      p {
        cursor: pointer;
        margin: 0;
        font-size: 14px;
        color: color(purple);
      }
      .arrow {
        @include imgCont;
        width: 10px;
        height: 10px;
        transform: rotate(-90deg);
        margin: 0 0 0 15px;
      }
    }
  }
}
