@import "../../assets/styles/main.module";

.content {
  &:nth-child(even) {
    background-color: #ededed;
  }
  td {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    &.notFound {
      padding: 20px 10px;
      text-align: center;
    }
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
    &.desc {
      max-width: 200px;
      p {
        @include textOverflow(1);
        margin: 0;
      }
    }
    &.status {
      color: #4d4d4d;
      font-weight: 700;
      &.arrived {
        color: #008a37;
      }
      & > div {
        width: 140px;
      }
    }
    .selectionStatus {
      opacity: 1;
      & > div {
        &:nth-child(3) {
          span {
            color: #4d4d4d;
            &[aria-selected="true"] {
              color: #ffffff;
            }
          }
        }
      }
    }
    .imgCont {
      position: relative;
      width: 100px;
      height: 56.25px;
      overflow: hidden;
      &.details {
        cursor: pointer;
      }
      &.imgItem {
        border-radius: 6px;
      }
      img {
        @include imgAbsolute;
      }
    }
    .action {
      // width: 70px;
      display: flex;
      .imgAction {
        cursor: pointer;
        @include imgCont;
        width: 28px;
        height: 28px;
        margin: 0 0 0 5px;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}
.tableItem {
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: auto;
  margin: 30px 0px 0px;
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
