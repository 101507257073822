@import "../../assets/styles/main.module";

.progress {
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 10px;
  .container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    p {
      font-size: 10px;
      margin: 0px 0px 0px 10px;
    }
    .progressBar {
      position: relative;
      height: 10px;
      width: 150px;
      background: #eaeaea;
      border-radius: 2px;
      overflow: hidden;

      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: color(purple);
        transition: 0.5s;
      }
    }
  }
}
