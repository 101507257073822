@import "../../assets/styles/main.module";

.modalLogin {
  position: relative;
  backdrop-filter: unset;
  & > div {
    &:nth-child(2) {
      display: none;
    }
  }
}
.error {
  margin: 0;
  color: color(error);
  text-align: center;
  font-size: 14px;
}
.formLogin {
  width: 100%;
  margin: 70px 0 0;
  .forgot {
    display: block;
    color: color(primary);
    font-family: "Helvetica Bold";
    font-size: 10px;
    line-height: 12px;
    margin-top: 18px;
  }
  .input {
    background: color(very-light-pink);
    input {
      font-size: 14px;
      border-radius: 30px;
    }
  }
  .btnSubmit {
    button {
      border-radius: 30px;
      width: 100%;
      padding: 15px 20px;
      font-size: 16px;
      box-shadow: 0 8px 24px 0 rgba(11, 153, 142, 0.3);
    }
  }
}
.forgot {
  cursor: pointer;
  margin: 22px 0 30px;
  font-size: 12px;
  line-height: 14px;
  color: color(purple);
}
.copy {
  font-size: 12px;
  font-weight: lighter;
  width: 100%;
  text-align: center;
  color: color(lightBlack);
  margin: 0;
  span {
    display: block;
  }
}
