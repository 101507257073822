@import "../../assets/styles/main.module";

.app {
  .content {
    position: relative;
    padding-top: 60px;
    margin: 0px auto;
    border: none;
  }
  .header {
    z-index: 2;
  }
  .content {
    position: relative;
    z-index: 1;
    padding: 90px 30px 50px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
  }
}
