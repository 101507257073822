@import "../../assets/styles/main.module";

.richText {
  width: 100%;
  & > * {
    font-family: font(primary) !important;
  }
  & > div {
    &:nth-child(2) {
      border-radius: 8px;
      border: solid 2px #e9eff4;
    }
  }
}
