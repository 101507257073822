@import "../../../assets/styles/main.module";

.modalSuccess {
  margin: 30px 0 0;
  h4 {
    margin: 0;
    font-weight: 800 !important;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  p {
    text-align: center;
    margin: 5px 0 0;
    font-weight: 200 !important;
    font-size: 13px;
    line-height: 16px;
    color: color(lightBlack);
  }
  .btnSubmit {
    margin: 25px 0 0 !important;
    button {
      width: 100%;
      border-radius: 28px;
      padding: 15px 20px;
    }
  }
}
.copy {
  margin: 120px 0 0;
  font-size: 12px;
  font-weight: lighter;
  width: 100%;
  text-align: center;
  color: color(lightBlack);
  span {
    display: block;
  }
}
