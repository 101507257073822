@import "../../../assets/styles/main.module";

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.3);
  .modalContent {
    max-width: 380px;
    margin: auto;
  }
}
