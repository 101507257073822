@import "../../assets/styles/main.module";

.richWithLabel {
  width: 80%;
  & > p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: color(lightBlack);
    margin: 0;
  }
  .richText {
    width: 80;
    margin: 15px 0 0;
  }
}
