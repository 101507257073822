@import "../../assets/styles/main.module";

.inputWithLabel {
  p {
    font-weight: 600;
    color: color(lightBlack);
    font-size: 14px;
    line-height: 16px;
    margin: 0px;
    .required {
      color: color(red);
      margin-left: 0;
    }
    span {
      margin-left: 5px;
      color: color(lightGrey);
    }
  }
}
