@import "../../assets/styles/main.module";

.totalAmount {
  display: flex;
  align-items: center;
  margin: 50px 0 0;

  &>* {
    margin: 0 0 0 40px;
  }

  %rectangle {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }

  .markingCode {
    @extend %rectangle;
    margin: 0 25px 0 0;
    padding-left: 20px;
    height: 45px;
    width: 300px;
    display: flex;
    align-items: center;

    &>div>input {
      border: none;
      margin-left: 15px;
      font-size: 13px;
      color: #acacac;
      font-family: "Nunito Sans", sans-serif, -apple-system;
      font-weight: normal;
    }
  }

  .inputDate {
    @extend %rectangle;
    order: 1;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;

    &>div {
      &>div {
        &>div {
          border: none;
        }
      }
    }
  }

  .total {
    order: 2;

    &.right {
      order: 3;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: color(lightBlack2);
    }

    h4 {
      margin: 2px 0 0;
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      color: color(lightBlack2);
    }
  }

  .btnReset {
    order: 9;
    margin-left: 50px !important;
  }
}