@import "../../assets/styles/main.module";

.inputPlc {
  &.alignIconRight,
  &.alignIconLeft {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    input {
      flex: 1;
    }
  }
  &.wIcon {
    border: 1px solid #dcdcdc;
    border-radius: 30px;
    input {
      border: none;
    }
  }
  &.alignIconLeft {
    .imgCont {
      order: 0;
      margin-left: 20px;
    }
    input {
      padding: 12px 20px 12px 0px;
      margin-left: 5px;
    }
  }
  &.alignIconRight {
    .imgCont {
      margin-right: 20px;
      order: 1;
    }
    input {
      padding: 12px 0px 12px 20px;
      margin-right: 5px;
    }
    .info {
      order: 9;
    }
  }
  &.multiple {
    display: flex;
    align-items: center;
    .imgCont {
      margin-left: 20px;
    }
    input {
      order: 1;
      flex: 1;
      padding: 12px 0px;
      margin: 0px 5px;
    }
    .imgCont2 {
      order: 2;
      margin-right: 20px;
      margin-left: 0px;
    }
  }
  &.border {
    input {
      border: none;
      padding: 0px 0px 12px 0px;
      border-bottom: 1px solid #dcdcdc;
      border-radius: 0;
    }
  }
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  input {
    width: 100%;
    background: transparent;
    color: color(black);
    padding: 12px 20px;
    font-family: font(primary);
    font-size: 14px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    outline: none;
    &::placeholder {
      font-size: 14px;
      font-style: italic;
      color: #acacac;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: color(black);
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      font-size: 14px;
      background-color: #ffffff !important;
    }
    &.wIcon {
      border: none;
      border-radius: 0;
    }
  }
  .datePicker {
    z-index: 1000;
    position: absolute;
    & > div {
      & > div {
        & > div {
          &:nth-child(1) {
            background: linear-gradient(lighten(color(purple), 10%), color(purple));
            border-color: color(purple);
            &::after {
              border-bottom-color: color(purple);
            }
          }
        }
      }
    }
  }
  .imgCont {
    position: relative;
    width: 24px;
    height: 24px;
    &.password {
      cursor: pointer;
    }
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.info {
  margin: 10px 0px 0px !important;
  color: color(lightGrey) !important;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  font-style: italic;
}
