@import "../../assets/styles/main.module";

.tableHeader {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  tr {
    th {
      padding: 20px 15px;
      color: color(lightBlack);
      font-size: 14px;
      line-height: 16px;
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
      &.hide {
        position: relative;
        display: none;
      }
      span {
        &.pointer {
          cursor: pointer;
        }
      }
      &.sort {
        span {
          display: flex;
          align-items: center;
        }
      }
      &.location {
        max-width: 120px;
      }
      .selectLocation {
        opacity: 1;
        padding: 10px 15px !important;
        & > div {
          &:nth-child(1) {
            max-width: calc(120px - 60px);
            display: flex;
            flex-flow: row;
            overflow: hidden;
            span {
              @include textOverflow(1);
            }
            input {
              // display: none;
            }
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
            right: 0;
            left: unset;
            width: 200px;
            max-height: 300px;
            span {
              font-family: font(primary);
              font-weight: normal;
            }
          }
        }
      }
      .arrowSort {
        margin-left: 10px;
        img {
          display: block;
          width: 14px;
          &.desc {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
