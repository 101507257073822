@import "../../assets/styles/main.module";

.btnClose {
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    content: "";
    background: color(black);
    width: 85%;
    height: 2px;
    transform: rotate(45deg);
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}
