@import "../../assets/styles/main.module";

.radioLabel {
  & > p {
    font-weight: 600;
    color: color(lightBlack);
    font-size: 14px;
    line-height: 16px;
    margin: 0px;
    .required {
      color: color(red);
      margin-left: 0;
    }
  }
  .container {
    display: flex;
    & > div {
      margin: 20px 0 0 50px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}
