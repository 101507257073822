@import "../../assets/styles/main.module";

.tableFooter {
  border-top: 1px solid #d6d6d6;
  td {
    padding: 20px 15px;
    .actionPlc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        display: flex;
        align-items: center;
      }
      p {
        margin: 0px 0px 0px 15px;
        font-size: 14px;
        line-height: 16px;
        &:first-child {
          margin: 0px;
        }
      }
      select {
        margin: 0px 0px 0px 15px;
      }
    }
    .btnAction {
      margin: 0px 0px 0px 30px;
      display: flex;
      align-items: center;
      p {
        margin: 0 5px;
      }
    }
  }
}

.btnNav {
  cursor: pointer;
  @include imgCont;
  width: 28px;
  height: 28px;
  img {
    max-width: 90%;
    max-height: 90%;
  }
  &.next {
    transform: rotate(180deg);
  }
  &.prev {
    // transform: rotate(90deg);
  }
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
