@import "../../assets/styles/main.module";

.inputDate {
  opacity: 1;
  & > div {
    width: 100%;
    & > div {
      padding: 12px 20px;
      border-radius: 8px;
      border: 1px solid #dcdcdc;
      & > div {
        &:nth-child(1) {
          input {
            font-family: font(primary);
            font-style: italic;
            font-size: 14px;
            color: color(lightBlack);
            &::placeholder {
              font-size: 14px;
              font-style: italic;
              color: #acacac;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-text-fill-color: color(black);
              -webkit-box-shadow: 0 0 0px 1000px #ededed inset;
              font-size: 14px;
            }
          }
          span {
            color: color(lightBlack);
            font-size: 14px;
          }
        }
      }
      & > button {
        padding: 0;
      }
    }
  }
}
